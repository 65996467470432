<template>
  <section>
    <services />
  </section>
</template>

<script>
  export default {
    metaInfo () {
      return {
        title: 'Creative'
      }
    }
  }
</script>
